import { coinConvert } from "@stakeordie/griptape.js"
import React, { useContext, useEffect, useState } from "react"
import { KeplrToolsContext } from "../context/KeplrToolsContext"
import globalStores from "../stores/globalStores"
import BuyTicketsModal from "./BuyTicketsModal"
import Loading from "./Loading"
import MainAreaTimeline from "./MainAreaTimeline"
import MyNFTsRow from "./MyNFTsRow"

export default () => {
    const keplrTools = useContext(KeplrToolsContext)
    const { auctions, auctionsLoading } = globalStores.factoryStore;
    const { auctionInfoLoading, auctionInfo } = globalStores.auctionStore;
    const { myNFTs, getMyNFTsLoading } = globalStores.myNFTsStore;

    const [buyTicketsModal, setBuyTicketsModal] = useState(false);

    if (!keplrTools || !auctionInfo) return null
    return (
        <>
            <div className="flex flex-row w-full my-9">
                {auctionsLoading && <Loading variantBlack={false} />}
                {!auctionsLoading && auctions && auctions.length === 0 &&
                    <div className="text-color-11 text-xl font-circular-std-bold">No active auctions...</div>
                }
                {auctions && auctions.length === 0 && auctionInfoLoading && <Loading variantBlack={false} />}
                {auctionInfo &&
                    <>
                        <div className="flex flex-col ">
                            <div className="text-color-13 text-6xl font-circular-std-bold">
                                {
                                    auctionInfoLoading ?
                                        <Loading variantBlack={false} /> :
                                        "" + coinConvert(auctionInfo.auction_state.mint_pot, 6, 'human', 2) + " SEFI"
                                }
                            </div>
                            <div className="text-color-8 text-xs font-circular-std-bold text-upper mt-2">
                                Lottery Jackpot
                            </div>
                        </div>
                        <div className="flex flex-col justify-center items-center ml-auto">
                            <button
                                className="bg-color-11 shadow-2xl rounded-3xl p-4 font-circular-std-bold text-sm text-color-1 hover:bg-color-13 w-48 disabled:opacity-50"
                                disabled={!auctionInfo.auction_state.mint_status} onClick={() => setBuyTicketsModal(true)}
                            >
                                Buy Tickets
                            </button>
                            <div className="text-color-8 text-sm font-circular-std-book mt-2">
                                {
                                    auctionInfoLoading ?
                                        <Loading variantBlack={false} /> :
                                        coinConvert(auctionInfo.configs.nft_price, 6, 'human', 2) + " SEFI/Each"
                                }
                            </div>
                        </div>
                    </>
                }
            </div>
            {
                getMyNFTsLoading && <div className="flex flex-row w-full my-2 justify-center "><Loading variantBlack={false} /></div>
            }
            {
                !getMyNFTsLoading && myNFTs && myNFTs.length > 0 &&
                <MyNFTsRow />
            }
            <BuyTicketsModal
                buyTicketsModal={buyTicketsModal}
                setBuyTicketsModal={setBuyTicketsModal}
            />
            <div className="border-1 border-color-3 my-4" />
        </>

        /*
        <Row style={{ padding: "0px", margin: "0px" }}>
            <Col>
                <span className="jackpot-value">
                    {
                        auctionInfoLoading ?
                            <Loading /> :
                            "" + coinConvert(auctionInfo.auction_state.mint_pot, 6, 'human', 2) + " SEFI"
                    }
                </span>
                <span className="jackpot-value-label">Lottery Jackpot</span>
            </Col>
            <Col style={{
                //@ts-ignore
                textAlign: "-webkit-right"
            }}>
                <button className="buy-tickets-button" disabled={!auctionInfo.auction_state.mint_status} onClick={() => setBuyTicketsModal(true)}>Buy Tickets</button>
                <span className="ticket-cost-label">
                    {
                        auctionInfoLoading ?
                            <Loading /> :
                            coinConvert(auctionInfo.configs.nft_price, 6, 'human', 2) + " SEFI/Each"
                    }
                </span>
            </Col>
            <BuyTicketsModal
                buyTicketsModal={buyTicketsModal}
                setBuyTicketsModal={setBuyTicketsModal}
            />
        </Row>
    */)
}