import { Dispatch, useContext } from "react"
import { KeplrToolsContext } from "../context/KeplrToolsContext";
import globalStores from "../stores/globalStores";

export default ({
    activeMenu,
    setActiveMenu
}: {
    activeMenu: string,
    setActiveMenu: Dispatch<string>
}) => {
    const keplrTools = useContext(KeplrToolsContext)
    const { setAuction } = globalStores.auctionStore;

    if (!keplrTools) return null

    return (
        <div className="flex flex-row mb-8">
            <div className={`text-color-11 text-xl font-circular-std-bold border-b-2 border-color-3 pb-6 w-full`}>
                Lotto
            </div>
            <div className={`${activeMenu === "Feed" ? "text-color-13" : "text-color-7"} ml-auto text-lg font-circular-std-bold px-4 border-b-2 ${activeMenu === "Feed" ? "border-color-13" : "border-color-3"} pb-6 cursor-pointer`}
                onClick={() => {
                    setActiveMenu("Feed")
                    setAuction(keplrTools.client, keplrTools.accountAddress, null)
                }}>
                Feed
            </div>
            {
                /*
                 <div className={`${activeMenu === "Orders" ? "text-color-13" : "text-color-7"}  text-lg font-circular-std-bold px-4 border-b-2 ${activeMenu === "Orders" ? "border-color-13" : "border-color-3"} pb-6 cursor-pointer`}
                onClick={() => {
                    setAuction(keplrTools.client, keplrTools.accountAddress, null)
                    setActiveMenu("Orders")
                }}>
                Orders
            </div>
                */
            }

        </div>
    )
}