import { coinConvert } from "@stakeordie/griptape.js";
import React, { Dispatch, useContext, useEffect, useState } from "react";
import { KeplrToolsContext } from "../context/KeplrToolsContext";
import globalStores from "../stores/globalStores";
import Loading from "./Loading";

export default ({
    buyTicketsModal,
    setBuyTicketsModal
}: {
    buyTicketsModal: boolean,
    setBuyTicketsModal: Dispatch<boolean>
}) => {
    const keplrTools = useContext(KeplrToolsContext)
    const { auctionInfo, mintLoading, mintNFTs } = globalStores.auctionStore;
    const [ticketCount, setTicketCount] = useState<string>("");
    const [success, setSuccess] = useState<boolean | null>(null);

    useEffect(() => {
        setTicketCount("")
        setSuccess(null)
    }, [buyTicketsModal])

    const totalSEFI = () => {
        if (!auctionInfo || ticketCount === "") return 0
        else return parseInt(auctionInfo.configs.nft_price) * parseInt(ticketCount)
    }

    if (!auctionInfo || !keplrTools) return null
    if (buyTicketsModal)
        return (
            <div className="fixed z-10 inset-0 overflow-y-auto">
                <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity">
                        <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
                        <div className="border-color-3 inline-block align-bottom bg-color-2 rounded-2xl text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full py-6 px-6">
                            {
                                success === null &&
                                <>
                                    <div className="flex flex-row w-full">
                                        <div className="flex flex-col font-circular-std-black text-color-13 text-2xl">
                                            Buy Tickets
                                        </div>
                                        <div className="flex flex-col justify-center items-center bg-color-3 text-color-13  ml-auto w-8 h-8 cursor-pointer rounded-3xl p-4 text-base" onClick={() => setBuyTicketsModal(false)}>
                                            X
                                        </div>
                                    </div>
                                    <div className="flex flex-row w-full pt-8 pb-4">
                                        <div className="flex flex-col font-circular-std-book text-color-13 text-base justify-center">
                                            How many tickets would you like to buy?
                                        </div>
                                        <div className="flex flex-col justify-center items-center ml-auto">
                                            <input
                                                value={ticketCount}
                                                className={
                                                    `
                                        ${ticketCount === "" ? "text-color-4" : "text-color-13"}
                                         w-32
                                         p-4 bg-color-3 rounded-lg font-circular-std-book text-4xl text-center`
                                                }
                                                placeholder="0000"
                                                onChange={(e) => {
                                                    const reg = new RegExp('^[0-9]+$');
                                                    let value = e.target.value
                                                    if (!reg.test(value)) value = ""
                                                    if (parseInt(value) > 9999) value = "9999"
                                                    setTicketCount(value)
                                                }}
                                            />
                                        </div>
                                    </div>
                                    <div className="border-1 border-color-4 my-1" />
                                    <div className="flex flex-row w-full py-4">
                                        <div className="flex flex-col justify-center text-color-8 font-circular-std-bold text-xs text-upper mr-4">
                                            Total
                                        </div>
                                        <div className="flex flex-col justify-center font-circular-std-bold text-xl text-color-13">
                                            {
                                                ticketCount !== "" ?
                                                    (coinConvert("" + totalSEFI(), 6, 'human', 2) + " SEFI") :
                                                    "0.00 SEFI"
                                            }
                                        </div>
                                    </div>
                                    <div className="border-1 border-color-4 my-1" />
                                    <div className="flex flex-row w-full mt-5">
                                        <button
                                            className={
                                                `
                                        ${!mintLoading && " disabled:opacity-50 hover:bg-color-13 "}
                                        w-full bg-color-11 rounded-3xl shadow-2xl p-4 font-circular-std-bold text-color-1 text-sm 
                                        `
                                            }
                                            disabled={ticketCount === "" || mintLoading}
                                            onClick={async () => {
                                                const res = await mintNFTs(keplrTools.client, parseInt(ticketCount), "" + totalSEFI(), keplrTools.accountAddress)
                                                setSuccess(res)
                                                //setBuyTicketsModal(false)
                                            }}>
                                            {
                                                mintLoading ?
                                                    <Loading variantBlack={true} />
                                                    :
                                                    ticketCount !== "" ?
                                                        "Pay " + (coinConvert("" + totalSEFI(), 6, 'human', 2) + " SEFI") :
                                                        "Pay 0.00 SEFI"
                                            }
                                        </button>
                                    </div>
                                </>
                            }
                            {
                                success === true &&
                                <>
                                    <div className="flex flex-row w-full justify-center items-center text-color-13 mb-2 mt-8">
                                        <i className="far fa-check-circle fa-5x "></i>
                                    </div>
                                    <div className="flex flex-row w-full justify-center items-center  text-color-13 my-2 font-circular-std-bold text-2xl">
                                        {"You Bought " + ticketCount + " Tickets!"}
                                    </div>
                                    <div className="flex flex-row w-full justify-center items-center mt-12">
                                        <button
                                            className="hover:bg-color-13 w-full bg-color-11 rounded-3xl shadow-2xl p-4 font-circular-std-bold text-color-1 text-sm"
                                            //@ts-ignore
                                            style={{ textAlign: "-webkit-center", marginBottom: "15px", width: "100%", height: "50px", background: "#E8E9EA", boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.25)", borderRadius: "24px" }}
                                            onClick={async () => {
                                                setBuyTicketsModal(false)
                                            }}
                                        >
                                            Got It
                                        </button>
                                    </div>
                                </>
                            }
                            {
                                success === false &&
                                <>
                                    <div className="flex flex-row w-full justify-center items-center text-color-13 mb-2 mt-8">
                                        <i className="far fa-times-circle fa-5x "></i>
                                    </div>
                                    <div className="flex flex-row w-full justify-center items-center  text-color-13 my-2 font-circular-std-bold text-2xl">
                                        Oh No, Something Went Wrong
                                    </div>
                                    <div className="flex flex-row w-full justify-center items-center  mt-12">
                                        <button
                                            className="hover:bg-color-13 w-full bg-color-11 rounded-3xl shadow-2xl p-4 font-circular-std-bold text-color-1 text-sm"
                                            //@ts-ignore
                                            style={{ textAlign: "-webkit-center", marginBottom: "15px", width: "100%", height: "50px", background: "#E8E9EA", boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.25)", borderRadius: "24px" }}
                                            onClick={async () => {
                                                setBuyTicketsModal(false)
                                            }}
                                        >
                                            Go Back
                                        </button>
                                    </div>
                                </>
                            }
                        </div>
                    </div>
                </div>
            </div >)
    else return null
}