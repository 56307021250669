import { useState } from 'react';
import { SigningCosmWasmClient } from 'secretjs';
import permitDetails from '../utils/permitDetails';
import globalStores from './globalStores';
export interface MyNFTsStore {
    myNFTs: string[] | null,
    getMyNFTs(client: SigningCosmWasmClient, myAddress: string, nftsContractAddress: string): Promise<void>,
    getMyNFTsLoading: boolean;
    claimRewardsLoading: boolean,
    claimRewards(client: SigningCosmWasmClient, id: string): Promise<void>,
}

export function useMyNFTsStore() {
    const [myNFTs, setMyNFTs] = useState<string[] | null>(null);
    const [getMyNFTsLoading, setGetMyNFTsLoading] = useState(false);

    const getMyNFTs = async (client: SigningCosmWasmClient, myAddress: string, nftsContractAddress: string) => {
        setGetMyNFTsLoading(true)
        let permit = JSON.parse(
            localStorage.getItem(`query_permit_${myAddress + "_" + nftsContractAddress}`) as string
        );
        if (!permit) permit = await permitDetails(myAddress, nftsContractAddress)

        const res = await client.queryContractSmart(nftsContractAddress, {
            with_permit: {
                query: { tokens: { owner: myAddress, limit: 10000 } },
                permit
            }
        })

        setMyNFTs(res.token_list.tokens)
        setGetMyNFTsLoading(false)
    }

    const [claimRewardsLoading, setClaimRewardsLoading] = useState<boolean>(false);
    const claimRewards = async (client: SigningCosmWasmClient, id: string) => {
        setClaimRewardsLoading(true)
        const { auctionSelected, auctionInfo, getAuctionInfo } = globalStores.auctionStore;
        if (!auctionInfo || !auctionSelected) return
        const fee = {
            amount: [{ amount: "500000", denom: "uscrt" }],
            gas: "1000000",
        };
        await client.execute(auctionInfo.configs.nfts_contract.contract!.address, {
            batch_send_nft: {
                sends: [{
                    contract: auctionSelected,
                    token_ids: [id],
                    msg: btoa(JSON.stringify({
                        claim_reward: {
                        }
                    }))
                }]
            }
        }, undefined, undefined, fee);
        await getAuctionInfo(client, auctionSelected)

        setClaimRewardsLoading(false)
    }

    return {
        myNFTs,
        getMyNFTsLoading,
        getMyNFTs,
        claimRewards,
        claimRewardsLoading
    };
}