import { useState } from 'react';
import { SigningCosmWasmClient } from 'secretjs';
import constants from '../constants';
import entropy from '../utils/entropy';
import globalStores from './globalStores';
export interface FactoryStore {
    auctions: SecretContract[] | null,
    auctionsStatus: string | null,
    auctionsLoading: boolean;
    getAuctions(client: SigningCosmWasmClient, status: string | null): Promise<void>,
    createAuctionLoading: boolean;
    createAuction(client: SigningCosmWasmClient): Promise<void>,
}

export function useFactoryStore() {
    const [auctionsStatus, setAuctionsStatus] = useState<string | null>(null);
    const [auctions, setAuctions] = useState<SecretContract[] | null>(null);
    const [auctionsLoading, setAuctionsLoading] = useState(false);

    const getAuctions = async (client: SigningCosmWasmClient, status: string | null) => {
        setAuctionsLoading(true)
        const res = await client.queryContractSmart(constants.FACTORY_CONTRACT_ADDRESS, {
            get_auctions: { status, page: 0, page_size: 3 }
        })
        setAuctions(res.get_auctions.auctions)
        const { getFeedAuctions } = globalStores.auctionStore;
        getFeedAuctions(client, res.get_auctions.auctions)
        setAuctionsStatus(status);
        setAuctionsLoading(false)
    }

    const [createAuctionLoading, setCreateAuctionLoading] = useState(false);

    const createAuction = async (client: SigningCosmWasmClient) => {
        setCreateAuctionLoading(true)
        try {
            await client.execute(constants.FACTORY_CONTRACT_ADDRESS, { create_auction: { prng_seed: btoa(entropy(27)) } });
            getAuctions(client, "mint;reveal;winner")
        } catch (e) {
            console.log(e)
        }
        setCreateAuctionLoading(false)
    }

    return { auctionsStatus, auctions, auctionsLoading, getAuctions, createAuctionLoading, createAuction };
}

export interface SecretContract {
    address: string,
    contract_hash: string
}