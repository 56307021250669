import { coinConvert } from "@stakeordie/griptape.js"
import React, { useContext, useEffect, useState } from "react"
import { KeplrToolsContext } from "../context/KeplrToolsContext"
import globalStores from "../stores/globalStores"
import Feed from "./Feed"
import GeneralTopRow from "./GeneralTopRow"
import Loading from "./Loading"
import MainAreaTimeline from "./MainAreaTimeline"
import MyNFTsRow from "./MyNFTsRow"
import NavBar from "./NavBar"

export default () => {
    const keplrTools = useContext(KeplrToolsContext)
    const [activeMenu, setActiveMenu] = useState("Feed")
    const { auctions, auctionsLoading, getAuctions } = globalStores.factoryStore;
    const { auctionInfoLoading, auctionInfo, setAuction, auctionSelected } = globalStores.auctionStore;
    const { myNFTs, getMyNFTsLoading } = globalStores.myNFTsStore;
    const { getBuyOrderBookState } = globalStores.orderbookStore;

    useEffect(() => {
        if (keplrTools) {
            getAuctions(keplrTools.client, null)
            setInterval(() => {
                getAuctions(keplrTools.client, null)
            }, 30000)
        }
    }, [keplrTools])

    /*useEffect(() => {
        if (keplrTools && auctionInfo && auctionInfo.auction_state.reveal_status) {
            getBuyOrderBookState(keplrTools.client)
        }
    }, [keplrTools, auctionInfo])*/

    if (!keplrTools) return null
    return (
        <div className="flex flex-col  w-full">
            <NavBar
                activeMenu={activeMenu}
                setActiveMenu={setActiveMenu}
            />
            {
                auctionsLoading && <Loading variantBlack={false} />
            }
            {
                !auctionSelected ? <Feed
                    setActiveMenu={setActiveMenu}
                /> :
                    <>
                        <GeneralTopRow />
                        <MainAreaTimeline />
                    </>
            }

        </div>
    )
}