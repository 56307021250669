import React, { useContext, useEffect } from 'react';
import { SigningCosmWasmClient } from 'secretjs';
import constants from './constants';
import MainArea from './conteiners/MainArea';
import WalletInfo from './conteiners/WalletInfo';
import KeplrToolsContextProvider, { KeplrToolsDispatchContext } from './context/KeplrToolsContext';
import { useAuctionStore } from './stores/auctionStore';
import { useFactoryStore } from './stores/factoryStore';
import globalStores from './stores/globalStores';
import { useMyNFTsStore } from './stores/myNFTsStore';
import { useOrderbookStore } from './stores/orderbookStore';
import { useWalletInfoStore } from './stores/walletInfoStore';

require('dotenv').config()

export default () => {
  globalStores.walletInfoStore = useWalletInfoStore();
  globalStores.factoryStore = useFactoryStore();
  globalStores.auctionStore = useAuctionStore()
  globalStores.myNFTsStore = useMyNFTsStore()
  globalStores.orderbookStore = useOrderbookStore()
  return (
    <div className="bg-color-1 min-h-screen">
      <KeplrToolsContextProvider>
        <KeplrSetup />
        <div className="container mx-auto flex flex-row flex-wrap pt-11">
          <div className="flex w-full md:w-9/12 px-11">
            <MainArea />
          </div>
          <div className="hidden md:flex md:w-3/12 justify-center">
            <WalletInfo />
          </div>
        </div>
      </KeplrToolsContextProvider>
    </div>

    /*
    <div style={{ backgroundColor: "#161F28", height: "100vh", width: "100vw", color: "white" }}>
      <KeplrToolsContextProvider>
        <KeplrSetup />
        <Container style={{ maxWidth: "80%" }}>
          <Row>
            <Col>
              <MainArea />
            </Col>
            <Col xs={4} style={{
              //@ts-ignore
              textAlign: "-webkit-center"
            }}>
              <WalletInfo />
            </Col>
          </Row>
        </Container>
      </KeplrToolsContextProvider>

    </div>*/
  );
}


const KeplrSetup = () => {
  const setKeplrTools = useContext(KeplrToolsDispatchContext);

  useEffect(() => {
    setupKeplr()
  }, [])

  const setupKeplr = async () => {
    const sleep = (ms: number) => new Promise((accept) => setTimeout(accept, ms));

    // Wait for Keplr to be injected to the page
    while (
      !window.keplr &&
      !window.getOfflineSigner &&
      !window.getEnigmaUtils
    ) {
      await sleep(10);
    }

    const keplr = await window.keplr;

    await keplr.experimentalSuggestChain({
      chainId: constants.CHAIN_ID,
      chainName: constants.CHAIN_ID,
      rpc: constants.RPC,
      rest: constants.REST,
      bip44: {
        coinType: 529,
      },
      coinType: 529,
      stakeCurrency: {
        coinDenom: 'SCRT',
        coinMinimalDenom: 'uscrt',
        coinDecimals: 6,
      },
      bech32Config: {
        bech32PrefixAccAddr: 'secret',
        bech32PrefixAccPub: 'secretpub',
        bech32PrefixValAddr: 'secretvaloper',
        bech32PrefixValPub: 'secretvaloperpub',
        bech32PrefixConsAddr: 'secretvalcons',
        bech32PrefixConsPub: 'secretvalconspub',
      },
      currencies: [
        {
          coinDenom: 'SCRT',
          coinMinimalDenom: 'uscrt',
          coinDecimals: 6,
        },
      ],
      feeCurrencies: [
        {
          coinDenom: 'SCRT',
          coinMinimalDenom: 'uscrt',
          coinDecimals: 6,
        },
      ],
      gasPriceStep: {
        low: 0.1,
        average: 0.25,
        high: 0.4,
      },
      features: ['secretwasm'],
    });

    await window.keplr.enable(constants.CHAIN_ID);

    let keplrOfflineSigner = window.getOfflineSigner(constants.CHAIN_ID);
    let acc_address = (await keplrOfflineSigner.getAccounts())[0].address

    let secretjs = new SigningCosmWasmClient(
      constants.REST,
      acc_address,
      keplrOfflineSigner,
      window.getEnigmaUtils(constants.CHAIN_ID),
      {
        // 300k - Max gas units we're willing to use for init
        init: {
          amount: [{ amount: "300000", denom: "uscrt" }],
          gas: "300000",
        },
        // 300k - Max gas units we're willing to use for exec
        exec: {
          amount: [{ amount: "300000", denom: "uscrt" }],
          gas: "300000",
        },
      }
    );

    setKeplrTools({
      client: secretjs,
      accountAddress: acc_address
    });
  }

  return null
}