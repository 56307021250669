import { bech32, coinConvert } from '@stakeordie/griptape.js'
import { useContext, useEffect, useState } from 'react'
import constants from '../constants'
import { KeplrToolsContext } from '../context/KeplrToolsContext'
import globalStores from '../stores/globalStores'
import Loading from './Loading'
import MyOrdersModal from './MyOrdersModal'

export default () => {
    const keplrTools = useContext(KeplrToolsContext)
    const { loadingScrtBalance, loadingSefiBalance, getScrtBalance, scrtBalance, sefiBalance, getSefiBalance } = globalStores.walletInfoStore;
    const { createAuctionLoading, createAuction } = globalStores.factoryStore;
    const { setAuctionToRevealStatus, changeToRevealLoading, revealLosers, revealLosersLoading, auctionInfo, auctionSelected } = globalStores.auctionStore
    const [myOrdersModal, setMyOrdersModal] = useState(false);

    useEffect(() => {
        if (keplrTools) {
            getScrtBalance(keplrTools.client, keplrTools.accountAddress);
            getSefiBalance(keplrTools.client, keplrTools.accountAddress);
        }
    }, [keplrTools])

    if (!keplrTools) return null
    const sefiBalanceRender = () => {
        if (loadingSefiBalance) return <Loading variantBlack={false} />
        if (!sefiBalance) return (
            <div onClick={async () => {
                await window.keplr.suggestToken(constants.CHAIN_ID, constants.SEFI_CONTRACT_ADDRESS);
                const sleep = (ms: number) => new Promise((accept) => setTimeout(accept, ms));
                await sleep(1000);
                getSefiBalance(keplrTools.client, keplrTools.accountAddress)
            }}>
                <i className="fas fa-key"></i> SEFI
            </div>
        )
        return "" + coinConvert(sefiBalance ? sefiBalance : "", 6, 'human', 2) + " SEFI"
    }

    return (
        <div className="hidden md:flex flex-col self-start sticky border-2 border-color-3 border-r rounded-2xl px-6 py-4 shadow-2xl bg-color-2">
            <div className=" font-circular-std-book text-base text-color-13 mt-1 mb-4">
                {bech32(keplrTools.accountAddress, 16)}
            </div>
            <div className="font-circular-std-book text-base text-color-8 my-1">
                {
                    loadingScrtBalance ?
                        <Loading variantBlack={false} /> :
                        "" + coinConvert(scrtBalance ? scrtBalance : "", 6, 'human', 2) + " SCRT"
                }
            </div>
            <div className="font-circular-std-book text-base text-color-8 my-1">
                {sefiBalanceRender()}
            </div>
            {
                auctionInfo && !auctionInfo.auction_state.mint_status &&
                <button
                    className="bg-color-3 rounded-3xl p-4 text-color-11 font-circular-std-bold text-base my-1 hover:bg-color-4"
                    onClick={() => {
                        setMyOrdersModal(true)
                    }}>
                    Orders
                </button>
            }

            <div className=" border-1 border-color-4 my-4" />
            <div className=" font-circular-std-book text-base text-color-13 mt-1 mb-4 text-center">
                TESTNET
            </div>
            <button
                className="bg-color-3 rounded-3xl p-4 text-color-11 font-circular-std-bold text-sm my-1 hover:bg-color-4"
                onClick={() => {
                    createAuction(keplrTools.client)
                }}
            >
                {
                    createAuctionLoading ? <Loading variantBlack={false} /> : "START AUCTION"
                }
            </button>

            {
                auctionSelected &&
                <button
                    className="bg-color-3 rounded-3xl p-4 text-color-11 font-circular-std-bold text-sm my-1 hover:bg-color-4"
                    onClick={() => {
                        setAuctionToRevealStatus(keplrTools.client)
                    }}
                >
                    {
                        changeToRevealLoading ? <Loading variantBlack={false} /> : "MINT TO REVEAL"
                    }
                </button>
            }
            {
                auctionSelected &&
                <button
                    className="bg-color-3 rounded-3xl p-4 text-color-11 font-circular-std-bold text-sm my-1 hover:bg-color-4"
                    onClick={() => {
                        revealLosers(keplrTools.client)
                    }}
                >
                    {
                        revealLosersLoading ? <Loading variantBlack={false} /> : "REVEAL EXECUTE"
                    }
                </button>
            }

            <MyOrdersModal
                myOrdersModal={myOrdersModal}
                setMyOrdersModal={setMyOrdersModal}
            />
        </div>
    )
}