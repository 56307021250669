import { useContext, useState } from "react";
import { KeplrToolsContext } from "../context/KeplrToolsContext";
import globalStores from "../stores/globalStores";
import calcTicketsWorth from "../utils/calcTicketsWorth";
import Loading from "./Loading";
import ViewMyNFTsModal from "./ViewMyNFTsModal";

export default () => {
    const keplrTools = useContext(KeplrToolsContext)
    const { auctionInfo } = globalStores.auctionStore
    const { myNFTs, claimRewards, claimRewardsLoading } = globalStores.myNFTsStore;
    const [myNFTsModal, setMyNFTsModal] = useState<boolean>(false);

    if (!myNFTs || !auctionInfo || !keplrTools) return null

    const calcMyActiveTickets = () => {
        let activeCount = 0;
        myNFTs.forEach((myNFT) => auctionInfo.in_play_tokens.includes(myNFT) && activeCount++)
        return activeCount
    }

    if (auctionInfo.auction_state.winner_id && myNFTs.includes(auctionInfo.auction_state.winner_id)) {
        return (
            <div className="flex flex-row w-full bg-color-3 rounded-3xl p-6 mb-5">
                <div className=" w-1/12">
                    <div className="flex justify-center items-center w-full h-full">
                        <img src={require("../assets/ticket.png").default} />
                    </div>
                </div>
                <div className=" w-8/12">
                    <div className="flex items-center w-full h-full">
                        <div className="text-color-13 font-circular-std-bold text-xl">
                            {
                                auctionInfo.auction_state.finished_status ?
                                    "Congratulations! You have claimed the rewards!"
                                    :
                                    "Congratulations! You have the winning ticket!"
                            }
                        </div>
                    </div>
                </div>
                <div className=" w-3/12">
                    <div className="flex justify-end w-full h-full" >
                        {
                            auctionInfo.auction_state.finished_status &&
                            <div
                                className="flex flex-row h-full justify-end items-center font-circular-std-book text-color-11 text-base cursor-pointer"
                                onClick={() => setMyNFTsModal(true)}
                            >
                                View Tickets
                                <i className="fas fa-arrow-right ml-3"></i>
                            </div>
                        }
                        {
                            auctionInfo.auction_state.winner_status &&
                            <button
                                onClick={() => claimRewards(keplrTools.client, auctionInfo.auction_state.winner_id!)}
                                disabled={auctionInfo.auction_state.finished_status}
                                className="font-circular-std-bold text-base text-color-1 bg-color-11 rounded-3xl shadow-2xl py-4 px-8"
                            > {
                                    claimRewardsLoading ? <Loading variantBlack={true} /> : "Claim Reward"
                                }</button>
                        }
                    </div>
                </div>
                <ViewMyNFTsModal
                    myNFTsModal={myNFTsModal}
                    setMyNFTsModal={setMyNFTsModal}
                />
            </div>
        )
    } else {
        return (
            <div className="flex flex-row w-full bg-color-3 rounded-3xl p-6 mb-5">
                <div className=" w-1/12">
                    <div className="flex justify-center items-center w-full h-full">
                        <img src={require("../assets/ticket.png").default} />
                    </div>
                </div>
                <div className=" w-8/12">
                    <div className="flex flex-col justify-center">
                        <div className="text-color-13 font-circular-std-bold text-xl">
                            {
                                auctionInfo.auction_state.reveal_count === 0 ?
                                    myNFTs.length + " Tickets Entered" :
                                    calcMyActiveTickets() > 0 ?
                                        "Your tickets are worth " + calcTicketsWorth(auctionInfo) + "x" :
                                        "Dang! All of Your Tickets Lost"
                            }
                        </div>
                        <div className="text-color-9 font-circular-std-book text-sm">
                            {
                                auctionInfo.auction_state.reveal_count === 0 ?
                                    "You're in the running!" :
                                    calcMyActiveTickets() > 0 ?
                                        "You have " + calcMyActiveTickets() + " active tickets!" :
                                        "You still have time to buy more!"
                            }

                        </div>
                    </div>
                </div>
                <div className=" w-3/12">
                    <div
                        className="flex flex-row h-full justify-end items-center font-circular-std-book text-color-11 text-base cursor-pointer"
                        onClick={() => setMyNFTsModal(true)}
                    >
                        View Tickets
                        <i className="fas fa-arrow-right ml-3"></i>
                    </div>
                </div>
                <ViewMyNFTsModal
                    myNFTsModal={myNFTsModal}
                    setMyNFTsModal={setMyNFTsModal}
                />
            </div>
        )
    }
}