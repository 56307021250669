import { getAddress } from '@stakeordie/griptape.js';
import { useState } from 'react';
import { SigningCosmWasmClient } from 'secretjs';
import constants from '../constants';
import permitDetails from '../utils/permitDetails';
import globalStores from './globalStores';
export interface OrderbookStore {
    buyOrderBookState: BuyOrderBookState | null,
    buyOrderBookStateLoading: boolean,
    getBuyOrderBookState(client: SigningCosmWasmClient): Promise<void>,
    addBuyOrderLoading: boolean,
    addBuyOrder(client: SigningCosmWasmClient, amountSnip20PerNFT: number, nftAmountToBuy: number): Promise<void>,
    userOrdersState: UserOrdersState | null,
    userOrdersStateLoading: boolean,
    getUserOrdersState(client: SigningCosmWasmClient, myAddress: string): Promise<void>,
    cancelBuyOrderLoading: boolean,
    cancelBuyOrder(client: SigningCosmWasmClient, price_per_nft: string, expected_position: number, revealed: boolean, order_id: number): Promise<void>,
    /*executeSellOrder(ids: string[], price: string, revealed: boolean): Promise<void>,
    executeSellOrderLoading: boolean,
    createSellOrder(ids: string[], price_per_nft: string): Promise<void>,
    createSellOrderLoading: boolean,
    cancelSellOrderLoading: boolean,
    cancelSellOrder(price_per_nft: string, expected_position: number, order_id: number): Promise<void>,
    executeBuyOrder(amount: string, price_per_nft: string, expected_orders: { id: number, revealed: boolean, pos: number, token_ids: string[] }[]): Promise<void>,
    executeBuyOrderLoading: boolean,*/
}

export function useOrderbookStore() {
    const [buyOrderBookState, setBuyOrderBookState] = useState<BuyOrderBookState | null>(null);
    const [buyOrderBookStateLoading, setBuyOrderBookStateLoading] = useState(false);

    const getBuyOrderBookState = async (client: SigningCosmWasmClient) => {
        setBuyOrderBookStateLoading(true)
        try {
            const { auctionInfo } = globalStores.auctionStore;
            if (!auctionInfo) return

            const res = await client.queryContractSmart(auctionInfo.configs.order_book_contract.contract!.address, {
                get_paginated_buy_order_book: {
                    revealed: false,
                    page_size: 20
                }
            })

            console.log(res)
            setBuyOrderBookState(res.get_paginated_buy_order_book)
        } catch (e: any) {
            console.log(e)
        }
        setBuyOrderBookStateLoading(false)
    }

    const [userOrdersState, setUserOrdersState] = useState<UserOrdersState | null>(null);
    const [userOrdersStateLoading, setUserOrdersStateLoading] = useState(false);

    const getUserOrdersState = async (client: SigningCosmWasmClient, myAddress: string,) => {
        setUserOrdersStateLoading(true)
        try {
            const { auctionInfo } = globalStores.auctionStore;
            if (!auctionInfo || !myAddress) return

            let permit = JSON.parse(
                localStorage.getItem(`query_permit_${myAddress + "_" + auctionInfo.configs.order_book_contract.contract!.address}`) as string
            );
            if (!permit) permit = await permitDetails(myAddress, auctionInfo.configs.order_book_contract.contract!.address)

            const res = await client.queryContractSmart(auctionInfo.configs.order_book_contract.contract!.address, {
                with_permit: {
                    query: { get_user_orders: { address: myAddress } },
                    permit
                }
            })
            setUserOrdersState(res.get_user_orders)
        } catch (e: any) {
            console.log(e)
        }
        setUserOrdersStateLoading(false)
    }

    const [addBuyOrderLoading, setAddBuyOrderLoading] = useState(false);

    const addBuyOrder = async (client: SigningCosmWasmClient, amountSnip20PerNFT: number, nftAmountToBuy: number) => {
        setAddBuyOrderLoading(true)
        try {
            const { auctionInfo } = globalStores.auctionStore;
            if (!auctionInfo) return

            await client.execute(constants.SEFI_CONTRACT_ADDRESS, {
                send: {
                    recipient: auctionInfo.configs.order_book_contract.contract!.address,
                    amount: "" + amountSnip20PerNFT,
                    msg: btoa(JSON.stringify({ add_buy_order: { nft_amount: nftAmountToBuy, revealed: false } }))
                }
            });
        } catch (e: any) {
            console.log(e)
        }
        setAddBuyOrderLoading(false)
    }

    const [cancelBuyOrderLoading, setCancelBuyOrderLoading] = useState(false);

    const cancelBuyOrder = async (client: SigningCosmWasmClient, price_per_nft: string, expected_position: number, revealed: boolean, order_id: number) => {
        setCancelBuyOrderLoading(true)
        try {
            const { auctionInfo } = globalStores.auctionStore;
            if (!auctionInfo) return
            await client.execute(auctionInfo.configs.order_book_contract.contract!.address, { cancel_buy_order: { price_per_nft, expected_position, revealed, order_id } });
        } catch (e: any) {
            console.log(e);
        }
        setCancelBuyOrderLoading(false)
    }
    /*
        const [executeSellOrderLoading, setExecuteSellOrderLoading] = useState(false);
    
        const executeSellOrder = async (ids: string[], price: string, revealed: boolean) => {
            setExecuteSellOrderLoading(true)
            try {
                const { auctionInfo, auctionSelected } = globalStores.auctionStore;
                if (!auctionInfo || !auctionSelected) return
                const nft = await createNFTContract(auctionInfo.configs.nfts_contract.contract!.address);
                // @ts-ignore
                await nft.executeSellOrder(
                    auctionInfo.configs.order_book_contract.contract!.address,
                    ids,
                    btoa(JSON.stringify({
                        execute_sell_order: {
                            price_per_nft: price,
                            revealed
                        }
                    })),
                )
                getOrderBooksState()
                getUserOrdersState()
                const { getMyNFTs } = globalStores.myNFTsStore;
                getMyNFTs()
            } catch (e: any) {
                notifications(e.message, "danger");
            }
            setExecuteSellOrderLoading(false)
        }
    
        const [createSellOrderLoading, setCreateSellOrderLoading] = useState(false);
    
        const createSellOrder = async (ids: string[], price_per_nft: string) => {
            setCreateSellOrderLoading(true)
            try {
                const { auctionInfo, auctionSelected } = globalStores.auctionStore;
                if (!auctionInfo || !auctionSelected) return
                const nft = await createNFTContract(auctionInfo.configs.nfts_contract.contract!.address);
                // @ts-ignore
                await nft.executeSellOrder(
                    auctionInfo.configs.order_book_contract.contract!.address,
                    ids,
                    btoa(JSON.stringify({
                        add_sell_order: {
                            price_per_nft
                        }
                    })),
                )
                getOrderBooksState()
                getUserOrdersState()
                const { getMyNFTs } = globalStores.myNFTsStore;
                getMyNFTs()
            } catch (e: any) {
                notifications(e.message, "danger");
            }
            setCreateSellOrderLoading(false)
        }
    
        const [cancelSellOrderLoading, setCancelSellOrderLoading] = useState(false);
    
        const cancelSellOrder = async (price_per_nft: string, expected_position: number, order_id: number) => {
            setCancelSellOrderLoading(true)
            try {
                const { auctionInfo } = globalStores.auctionStore;
                if (!auctionInfo) return
                const orderbook = createOrderBookContract(auctionInfo.configs.order_book_contract.contract!.address);
                await orderbook.cancelSellOrder(price_per_nft, expected_position, order_id);
                getOrderBooksState()
                getUserOrdersState()
                const { getMyNFTs } = globalStores.myNFTsStore;
                getMyNFTs()
            } catch (e: any) {
                notifications(e.message, "danger");
            }
            setCancelSellOrderLoading(false)
        }
    
        const [executeBuyOrderLoading, setExecuteBuyOrderLoading] = useState(false);
        const executeBuyOrder = async (amount: string, price_per_nft: string, expected_orders: { id: number, revealed: boolean, pos: number, token_ids: string[] }[]) => {
            setExecuteBuyOrderLoading(true)
            try {
                const { auctionInfo, auctionSelected } = globalStores.auctionStore;
                if (!auctionInfo || !auctionSelected) return
                const snip20 = createSNIP20Contract(auctionInfo.configs.token_contract.address);
                // @ts-ignore
                await snip20.send(
                    auctionInfo.configs.order_book_contract.contract!.address,
                    amount,
                    btoa(JSON.stringify({
                        execute_buy_order: {
                            price_per_nft,
                            expected_orders
                        }
                    }
                    )))
                getOrderBooksState()
                getUserOrdersState()
                const { getMyNFTs } = globalStores.myNFTsStore;
                getMyNFTs()
                getUserOrdersState()
            } catch (e: any) {
                notifications(e.message, "danger");
            }
            setExecuteBuyOrderLoading(false)
        }
    */
    return {
        buyOrderBookState,
        buyOrderBookStateLoading,
        getBuyOrderBookState,
        addBuyOrderLoading,
        addBuyOrder,
        getUserOrdersState,
        userOrdersState,
        userOrdersStateLoading,
        cancelBuyOrderLoading,
        cancelBuyOrder
        //executeBuyOrderLoading, executeBuyOrder, cancelSellOrderLoading, cancelSellOrder, createSellOrder, createSellOrderLoading, executeSellOrder, executeSellOrderLoading, orderBooksState, orderBooksStateLoading, getOrderBooksState, , getUserOrdersState, userOrdersState, cancelBuyOrder
    };
}


export interface BuyOrderBookState {
    orders: QueryBuyOrder[],
}

export interface QueryBuyOrder {
    id: number,
    price: string,
    buy_nft_amount: number,
    revealed: boolean,
}

export interface BuyOrderbookPriceOrders {
    orders: QueryBuyOrder[],
}

export interface UserOrdersState {
    buy_order_not_revealed_active: Order | null,
    buy_order_revealed_active: Order | null,
    sell_order_active: Order | null,
    user_order_history: OrderHistory[],
}
export interface Order {
    id: number,
    creator: string,
    price: string,
    is_buy: boolean,
    buy_nft_amount: number | null,
    sell_nft_ids: string[] | null,
    sell_nft_original_count: number | null,
    revealed: boolean | null,
}

export interface OrderHistory {
    block_height: number,
    block_time: number,
    order_book_type: string,
    price_per_nft: string,
    snip20_sent: string | null,
    snip20_received: string | null,
    bought_nft_count: number | null,
    sold_nft_count: number | null,
    royalties: string | null,
}