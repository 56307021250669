import { Dispatch, useState } from 'react';
import { SigningCosmWasmClient } from 'secretjs';
import constants from '../constants';
import entropy from '../utils/entropy';
import { SecretContract } from './factoryStore';
import globalStores from './globalStores';

export interface AuctionStore {
    auctionSelected: string | null,
    setAuction(client: SigningCosmWasmClient, myAddress: string, auctionIndex: number | null): Promise<boolean>,
    auctionInfo: AuctionInfo | null,
    auctionInfoLoading: boolean,
    mintLoading: boolean,
    mintNFTs(client: SigningCosmWasmClient, count: number, totalToPay: string, myAddress: string): Promise<boolean>,
    setAuctionToRevealStatus(client: SigningCosmWasmClient): Promise<void>,
    changeToRevealLoading: boolean,
    revealLosers(client: SigningCosmWasmClient): Promise<void>,
    revealLosersLoading: boolean,
    getAuctionInfo(client: SigningCosmWasmClient, auctionContractAddress: string): Promise<void>,
    getFeedAuctions(client: SigningCosmWasmClient, auctions: SecretContract[]): Promise<void>,
    getFeedAuctionsLoading: boolean;
    feedAuctionsInfo: AuctionInfo[] | null,
}

export function useAuctionStore() {
    const [feedAuctionsInfo, setFeedAuctionsInfo] = useState<AuctionInfo[] | null>(null);
    const [auctionSelected, setAuctionSelected] = useState<string | null>(null);
    const [auctionInfo, setAuctionInfo] = useState<AuctionInfo | null>(null);
    const [auctionInfoLoading, setAuctionInfoLoading] = useState<boolean>(false);
    const [mintLoading, setMintLoading] = useState<boolean>(false);
    const [changeToRevealLoading, setChangeToRevealLoading] = useState<boolean>(false);

    const setAuction = async (client: SigningCosmWasmClient, myAddress: string, auctionIndex: number | null) => {
        const { auctions } = globalStores.factoryStore;
        if (!auctions || !feedAuctionsInfo) return false
        setAuctionSelected(auctionIndex === null ? null : auctions[auctionIndex].address)
        setAuctionInfo(auctionIndex === null ? null : feedAuctionsInfo[auctionIndex])
        if (auctionIndex !== null) {
            const { getMyNFTs } = globalStores.myNFTsStore;
            getMyNFTs(client, myAddress, feedAuctionsInfo[auctionIndex].configs.nfts_contract.contract!.address)
        }
        return true
    }

    const getAuctionInfo = async (client: SigningCosmWasmClient, auctionContractAddress: string) => {
        setAuctionInfoLoading(true)
        const res = await client.queryContractSmart(auctionContractAddress, { get_auction_info: {} })
        setAuctionInfo(res.get_auction_info)
        setAuctionInfoLoading(false)
        return res.get_auction_info
    }

    const [getFeedAuctionsLoading, setGetFeedAuctionsLoading] = useState(false);

    const getFeedAuctions = async (client: SigningCosmWasmClient, auctions: SecretContract[]) => {
        setGetFeedAuctionsLoading(true)

        let promises = [];

        for (let auction of auctions) {
            promises.push(client.queryContractSmart(auction.address, {
                get_auction_info: {}
            }))
        }

        const responses = await Promise.all(promises);
        setFeedAuctionsInfo(responses.map((auction) => auction.get_auction_info))
        setGetFeedAuctionsLoading(false)
    }

    const mintNFTs = async (client: SigningCosmWasmClient, count: number, totalToPay: string, myAddress: string) => {
        if (!auctionSelected || !auctionInfo) throw Error("!auctionSelected || !auctionInfo");

        setMintLoading(true)
        try {
            const { auctions } = globalStores.factoryStore;
            if (!auctions || !feedAuctionsInfo) throw new Error();
            let auctioIndex = auctions.findIndex((auction) => auction.address === auctionSelected);
            if (auctioIndex === -1) throw new Error();

            const fee = {
                amount: [{ amount: "500000", denom: "uscrt" }],
                gas: "" + Math.ceil(75 * count + 500 + 0.15 * count) + "000", // y = 75x + 500 + proportionalMinted
            };
            await client.execute(constants.SEFI_CONTRACT_ADDRESS, {
                send: {
                    recipient: auctionSelected,
                    amount: totalToPay,
                    msg: btoa(JSON.stringify({
                        mint_nfts: {
                            count: count,
                            entropy: entropy(27),
                        }
                    }))
                }
            }, undefined, undefined, fee);
            setAuction(client, myAddress, auctioIndex)
        } catch (e: any) {
            //notifications(e.message, "danger");
            setMintLoading(false)
            return false
        }
        setMintLoading(false)
        return true
    }

    const setAuctionToRevealStatus = async (client: SigningCosmWasmClient) => {
        if (!auctionSelected || !auctionInfo) throw Error("!auctionSelected || !auctionInfo");
        setChangeToRevealLoading(true)
        try {
            const fee = {
                amount: [{ amount: "500000", denom: "uscrt" }],
                gas: "" + Math.ceil(0.38 * auctionInfo.auction_state.minted_count + 650) + "000", // y = 0.38x + 650
            };
            await client.execute(auctionSelected, { change_mint_to_reveal_status: {} }, undefined, undefined, fee);
            await getAuctionInfo(client, auctionSelected)
        } catch (e: any) {
            //notifications(e.message, "danger");
        }
        setChangeToRevealLoading(false)
    }

    const [revealLosersLoading, setRevealLosersLoading] = useState<boolean>(false);
    const revealLosers = async (client: SigningCosmWasmClient) => {
        if (!auctionSelected || !auctionInfo) throw Error("!auctionSelected || !auctionInfo");
        setRevealLosersLoading(true)
        try {
            const gas = calcRevealLosersGas(auctionInfo.durations, auctionInfo.in_play_tokens.length);
            const fee = {
                amount: [{ amount: "500000", denom: "uscrt" }],
                gas, // TODO
            };
            await client.execute(auctionSelected, { reveal_losers: { entropy: entropy(27) } }, undefined, undefined, fee);
            await getAuctionInfo(client, auctionSelected)
        }
        catch (e: any) {
            console.log(e)
            //notifications(e.message, "danger");
        }
        setRevealLosersLoading(false)
    }

    return {
        auctionSelected,
        auctionInfo,
        auctionInfoLoading,
        mintLoading,
        changeToRevealLoading,
        setAuction,
        mintNFTs,
        setAuctionToRevealStatus,
        revealLosers,
        revealLosersLoading,
        getAuctionInfo,
        getFeedAuctions,
        getFeedAuctionsLoading,
        feedAuctionsInfo
    };
}

export interface AuctionInfo {
    configs: {
        factory: SecretContract,
        nfts_contract: ContractInfo,
        order_book_contract: ContractInfo,
        dao_contract: SecretContract,
        token_contract: SecretContract,
        staking_contract: SecretContract,
        auction_index: number,
        auction_label: string,
        nft_price: string,
        is_stopped: boolean,
    }
    auction_state: AuctionState,
    durations: DurationsStructure,
    in_play_tokens: string[],
}
export interface AuctionState {
    finished_status: boolean,
    mint_pot: string,
    mint_status: boolean,
    minted_count: number,
    reveal_count: number,
    reveal_status: boolean,
    winner_id: string | null,
    winner_status: boolean
}

export interface ContractInfo {
    contract: SecretContract | null,
    info: {
        code_id: number,
        code_hash: string
    }
}
export interface DurationsStructure {
    mint_start: number,
    mint_expected_duration: number,
    mint_end: number | null,
    next_reveal: number | null,
    reveals: Reveal[],
}

export interface Reveal {
    reveal_expected_duration: number,
    reveal_count_per: number,
    reveal_expected_execution_time: number | null,
    reveal_excution_time: number | null,
}

const calcRevealLosersGas = (durations: DurationsStructure, totalCount: number) => {
    if (durations.next_reveal === null || durations.next_reveal === undefined) throw Error("Gas Calc Error")
    let countToReveal = totalCount * durations.reveals[durations.next_reveal].reveal_count_per * 0.01;

    if (durations.next_reveal === durations.reveals.length - 1) { // It means we are going to winner status
        return "" + (110 * countToReveal + 570000) * 10
    } else {
        return "" + (550 * countToReveal + 200000) * 10
    }

}