import { coinConvert } from "@stakeordie/griptape.js";
import { Dispatch, useContext, useEffect } from "react";
import { KeplrToolsContext } from "../context/KeplrToolsContext";
import globalStores from "../stores/globalStores";
import calcTicketsWorth from "../utils/calcTicketsWorth";
import Loading from "./Loading";
import { Countdown } from "./MainAreaTimeline";

export default ({
    setActiveMenu
}: {
    setActiveMenu: Dispatch<string>
}) => {
    const keplrTools = useContext(KeplrToolsContext)
    const { auctions, auctionsLoading, getAuctions } = globalStores.factoryStore;
    const { auctionInfoLoading, auctionInfo, setAuction, auctionSelected, feedAuctionsInfo } = globalStores.auctionStore;

    if (!keplrTools || !feedAuctionsInfo || auctionSelected || !auctions) return null

    return (
        <>
            {
                feedAuctionsInfo.map((auction, i) =>
                    <div className="flex flex-row w-full bg-color-2 rounded-3xl p-6 mb-5 my-4">
                        <div className=" w-3/12">
                            <div className="flex flex-col border-r-2 border-color-3">
                                <div className="text-color-13 text-base font-circular-std-bold">
                                    {"Lottery " + auction.configs.auction_index}
                                </div>
                                <div className="text-color-13 text-4xl font-circular-std-bold my-2">
                                    {
                                        auctionInfoLoading ?
                                            <Loading variantBlack={false} /> :
                                            "" + coinConvert(auction.auction_state.mint_pot, 6, 'human', 2) + " SEFI"
                                    }
                                </div>
                                <div className="text-color-8 text-xs font-circular-std-bold text-upper">
                                    Jackpot
                                </div>
                            </div>
                        </div>
                        <div className=" w-8/12 self-center">

                            {
                                auction.auction_state.mint_status &&
                                <div className="flex flex-col justify-center items-center">
                                    <div>
                                        <span className="text-upper font-circular-std-bold text-sm text-color-13 mb-2">
                                            Pre-sale ends
                                        </span>
                                        <Countdown auctionInfo={auction} />
                                    </div>

                                </div>
                            }
                            {
                                auction.auction_state.reveal_status &&
                                <div className="flex flex-row justify-center items-center">
                                    <div className="flex flex-col w-1/2 items-center">
                                        <div className="flex flex-row">
                                            <div className="self-center">
                                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <circle cx="12" cy="12" r="12" fill="#454C53" />
                                                    <path d="M8 15.3333L15.3333 8M15.3333 8V14M15.3333 8H9.33333" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                                </svg>
                                            </div>
                                            <div className="ml-2">
                                                <div className="font-circular-std-bold text-color-13 text-xl">
                                                    {
                                                        (Math.round(((calcTicketsWorth(auction) - 1) * 100) * 100) / 100) + "%"
                                                    }
                                                </div>
                                                <div className="font-circular-std-bold text-upper text-color-8 text-base">Original Price</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="flex flex-col w-1/2  items-center">
                                        <div>
                                            <div className="font-circular-std-bold text-color-13 text-xl">
                                                {
                                                    auction.auction_state.minted_count - auction.auction_state.reveal_count
                                                }
                                            </div>
                                            <div className="font-circular-std-bold text-upper text-color-8 text-base">Tickets Left</div>
                                        </div>

                                    </div>
                                </div>

                            }

                        </div>
                        <div className=" w-1/12">
                            <div
                                className="flex flex-row h-full justify-center items-center font-circular-std-book text-color-11 text-xl cursor-pointer"
                                onClick={() => {
                                    setAuction(keplrTools.client, keplrTools.accountAddress, i)
                                    setActiveMenu("")
                                }}
                            >
                                <i className="fas fa-arrow-right"></i>
                            </div>
                        </div>
                    </div>
                )
            }
        </>
    )
}